var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Is Selected Item", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can validate if an option is selected with the property "),
        _c("code", [_vm._v("is-selected.sync")]),
        _vm._v(
          " and do with it multiple variants with changing the text of the selected options"
        ),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vs-select",
            {
              staticClass: "selectExample",
              model: {
                value: _vm.select1,
                callback: function ($$v) {
                  _vm.select1 = $$v
                },
                expression: "select1",
              },
            },
            _vm._l(_vm.options1, function (item, index) {
              return _c("vs-select-item", {
                key: index,
                attrs: {
                  "is-selected": item.isSelected,
                  value: item.value,
                  text: item.isSelected ? item.selectedText : item.label,
                },
                on: {
                  "update:isSelected": function ($event) {
                    return _vm.$set(item, "isSelected", $event)
                  },
                  "update:is-selected": function ($event) {
                    return _vm.$set(item, "isSelected", $event)
                  },
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template lang="html">\n  <div class="con-select-example">\n\n    <vs-select class="selectExample" v-model="select1" >\n      <vs-select-item\n        :is-selected.sync="item.isSelected"\n        :key="index"\n        :value="item.value"\n        :text="item.isSelected?item.selectedText:item.label"\n        v-for="(item,index) in options1"\n        />\n    </vs-select>\n\n  </div>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      select1:3,\n      options1:[\n        {value: 1, label: \'label 1\', selectedText: \'show after select label 1\', isSelected: false },\n        {value: 2, label: \'label 2\', selectedText: \'show after select label 2\', isSelected: false },\n        {value: 3, label: \'label 3\', selectedText: \'show after select label 3\', isSelected: false },\n      ],\n    }\n  }\n}\n</script>\n\n<style>\n.selectExample {\n  margin: 10px;\n}\n.con-select-example {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.con-select .vs-select {\n  width: 100%\n}\n@media (max-width: 550px) {\n  .con-select {\n    flex-direction: column;\n  }\n  .con-select .vs-select {\n    width: 100%\n  }\n}\n</style>\n    '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }