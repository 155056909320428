var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Multiple", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can have a selection with multiple selections with the property "
        ),
        _c("code", [_vm._v("multiple")]),
        _vm._v(
          ". If you need the user to select only a certain number of options you can use the property "
        ),
        _c("code", [_vm._v("max-selected")]),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vs-select",
            {
              staticClass: "selectExample",
              attrs: { placeholder: "Multiple", multiple: "" },
              model: {
                value: _vm.select1,
                callback: function ($$v) {
                  _vm.select1 = $$v
                },
                expression: "select1",
              },
            },
            _vm._l(_vm.options1, function (item, index) {
              return _c("vs-select-item", {
                key: index,
                attrs: { value: item.value, text: item.text },
              })
            }),
            1
          ),
          _c(
            "vs-select",
            {
              staticClass: "selectExample",
              attrs: {
                placeholder: "Multiple and autocomplete",
                multiple: "",
                "vs-autocomplete": "",
              },
              model: {
                value: _vm.select2,
                callback: function ($$v) {
                  _vm.select2 = $$v
                },
                expression: "select2",
              },
            },
            _vm._l(_vm.options2, function (item, index) {
              return _c("vs-select-item", {
                key: index,
                attrs: { value: item.value, text: item.text },
              })
            }),
            1
          ),
          _c(
            "vs-select",
            {
              staticClass: "selectExample",
              attrs: {
                placeholder: "Max Selected",
                "max-selected": "2",
                multiple: "",
              },
              model: {
                value: _vm.select3,
                callback: function ($$v) {
                  _vm.select3 = $$v
                },
                expression: "select3",
              },
            },
            _vm._l(_vm.options3, function (item, index) {
              return _c("vs-select-item", {
                key: index,
                attrs: { value: item.value, text: item.text },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template lang="html">\n  <div class="con-select-example">\n\n    <vs-select placeholder="Multiple" multiple class="selectExample" v-model="select1">\n      <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in options1" />\n    </vs-select>\n\n    <vs-select placeholder="Multiple and autocomplete" multiple vs-autocomplete class="selectExample" v-model="select2">\n      <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in options2" />\n    </vs-select>\n\n    <vs-select placeholder="Max Selected" max-selected="2" multiple class="selectExample" v-model="select3">\n      <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in options3" />\n    </vs-select>\n\n  </div>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      select1: [],\n      select2: [],\n      select3: [],\n      options1: [\n        { text: \'IT\', value: 0 },\n        { text: \'Blade Runner\', value: 2 },\n        { text: \'Thor Ragnarok\', value: 3 },\n      ],\n      options2: [\n        { text: \'Square\', value: 1 },\n        { text: \'Rectangle\', value: 2 },\n        { text: \'Rombo\', value: 3 },\n        { text: \'Romboid\', value: 4 },\n        { text: \'Trapeze\', value: 5 },\n        { text: \'Triangle\', value: 6 },\n        { text: \'Polygon\', value: 7 },\n        { text: \'Regular polygon\', value: 8 },\n        { text: \'Circumference\', value: 9 },\n        { text: \'Circle\', value: 10 },\n        { text: \'Circular sector\', value: 11 },\n        { text: \'Circular trapeze\', value: 12 },\n      ],\n      options3: [\n        { text: \'Red\', value: 1 },\n        { text: \'Green\', value: 2 },\n        { text: \'Blue\', value: 3 },\n        { text: \'Purple\', value: 4 },\n      ],\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }