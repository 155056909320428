var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Color", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can change the color of the select with the property "),
        _c("code", [_vm._v("color")]),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("span", [
            _vm._v("Only "),
            _c("strong", [_vm._v("RGB")]),
            _vm._v(" and "),
            _c("strong", [_vm._v("HEX")]),
            _vm._v(" colors are supported."),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.colorx,
                expression: "colorx",
              },
            ],
            attrs: { type: "color", value: "" },
            domProps: { value: _vm.colorx },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.colorx = $event.target.value
              },
            },
          }),
          _c(
            "vs-select",
            {
              staticClass: "selectExample",
              attrs: { color: _vm.colorx },
              model: {
                value: _vm.select1,
                callback: function ($$v) {
                  _vm.select1 = $$v
                },
                expression: "select1",
              },
            },
            _vm._l(_vm.options1, function (item, index) {
              return _c("vs-select-item", {
                key: index,
                attrs: { value: item.value, text: item.text },
              })
            }),
            1
          ),
          _c(
            "vs-select",
            {
              staticClass: "selectExample",
              attrs: { color: _vm.select2 },
              model: {
                value: _vm.select2,
                callback: function ($$v) {
                  _vm.select2 = $$v
                },
                expression: "select2",
              },
            },
            _vm._l(_vm.options2, function (item, index) {
              return _c("vs-select-item", {
                key: index,
                attrs: { value: item.value, text: item.text },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <div class=\"con-select-example\">\n\n    <input v-model=\"colorx\" type=\"color\" value=\"\">\n\n    <vs-select :color=\"colorx\" class=\"selectExample\" v-model=\"select1\">\n      <vs-select-item :key=\"index\" :value=\"item.value\" :text=\"item.text\" v-for=\"(item,index) in options1\" />\n    </vs-select>\n\n    <vs-select :color=\"select2\" class=\"selectExample\" v-model=\"select2\">\n      <vs-select-item :key=\"index\" :value=\"item.value\" :text=\"item.text\" v-for=\"(item,index) in options2\" />\n    </vs-select>\n\n  </div>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      colorx:'#103767',\n      select1:3,\n      select2:'primary',\n      options1:[\n        {text: 'Square', value: 1},\n        {text: 'Rectangle', value: 2},\n        {text: 'Rombo', value: 3},\n        {text: 'Romboid', value: 4},\n        {text: 'Trapeze', value: 5},\n        {text: 'Triangle', value: 6},\n        {text: 'Polygon', value: 7},\n        {text: 'Regular polygon', value: 8},\n        {text: 'Circumference', value: 9},\n        {text: 'Circle', value: 10},\n        {text: 'Circular sector', value: 11},\n        {text: 'Circular trapeze', value: 12},\n      ],\n      options2:[\n        {text: 'Primary', value: 'primary'},\n        {text: 'Success', value: 'success'},\n        {text: 'Danger', value: 'danger'},\n        {text: 'Warning', value: 'warning'},\n        {text: 'Dark', value: 'dark'},\n      ],\n    }\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }